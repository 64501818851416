import { Pills } from 'components/UI/Pills/Pills';
import type { WidgetMenuProps } from './widget-menu-default';

const WidgetMenuPill = ({ context }: { context: WidgetMenuProps }) => {
  return (
    <div>
      <Pills items={context.model.menu} disableActiveState />
    </div>
  );
};

export default WidgetMenuPill;
